<template>
  <b-overlay :show="showOverlay" rounded="sm">
    <div>
      <div class="all row mt-4">
        <div class="col-6">
          <p class="subtitle">Connector Type</p>
          <b-form-select
            class="shape-dropdown"
            :disabled="true"
            v-model="type"
            :options="types"
          ></b-form-select>
          <p class="subtitle mt-3">
            Connector Name
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="Please enter a display name for your connector."
            ></b-icon>
          </p>
          <b-form-input
            v-model="name"
            type="text"
            placeholder="Connector Name"
            class="shape col-12"
          ></b-form-input>

          <p v-if="tool === 'Trello'" class="subtitle mt-3">
            Connect to Trello
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="Click on the button to link your Trello account."
            ></b-icon>
          </p>
          <b-button
            v-if="tool === 'Trello'"
            variant="primary"
            class="shape col-12"
            v-on:click="openTrelloAppWindow()"
          >
            <img src="../../assets/trello.png" class="icon" />
            Connect to Trello
          </b-button>
          <p v-if="tool === 'ClickUp'" class="subtitle mt-3">
            Connect to ClickUp
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="Click on the button to link your ClickUp Workspace."
            ></b-icon>
          </p>
          <b-button
            v-if="tool === 'ClickUp'"
            variant="primary"
            class="shape col-12"
            v-on:click="openClickUpAppWindow()"
          >
            <img src="../../assets/clickup.png" class="icon" />
            Connect to ClickUp
          </b-button>

          <p v-if="tool === 'Monday.com'" class="subtitle mt-3">
            First: Install Altosio Application
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="Click on the button to Install Altosio Application in your Monday.com Workspace"
            ></b-icon>
          </p>

          <b-button
            v-if="tool === 'Monday.com'"
            variant="primary"
            class="shape col-12"
            v-on:click="installAltosioAppOnMondayEnvironment()"
          >
            <img src="../../assets/monday.png" class="icon" />
            Install Altosio App
          </b-button>
          <!-- OLD TRELLO LOGIN -->
          <!-- <p v-if="tool === 'Trello'" class="subtitle mt-3">App Key
           <b-icon icon="info-circle-fill" scale="1" style="cursor: pointer" variant="secondary"
            title="Please click on the info icon to see how to generate the App Key."
            @click="openTokenArticle">
           </b-icon>
        </p>
        <b-form-input
          v-if="tool === 'Trello'"
          v-model="appKey"
          type="text"
          placeholder="Enter API Key"
          class="shape col-12"
        ></b-form-input> -->

          <p
            v-if="isOffice365(tool) || tool === 'Teams User Chats'"
            class="subtitle mt-3"
          >
            Tenant Name
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="The name of your tenant is the prefix of your Sharepoint URL. This field will be automatically filled."
            ></b-icon>
          </p>
          <b-form-input
            v-if="isOffice365(tool) || tool === 'Teams User Chats'"
            v-model="tenant"
            type="text"
            :disabled="true"
            placeholder="Filled automatically"
            class="shape col-12"
          ></b-form-input>

          <p v-if="isOffice365(tool)" class="subtitle mt-3">
            Migration Account
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="Email of the migration account. This account does not need any admin priveleges. It just needs a valid license that allows access to Exchange and MS Teams. It also needs to bypass MFA and Azure Security Defaults. We recommend that you create a dedicated account for the migration."
            ></b-icon>
          </p>
          <b-form-input
            v-if="isOffice365(tool)"
            v-model="username"
            type="text"
            placeholder="Email"
            class="shape col-12"
          ></b-form-input>
        </div>

        <div class="col-6">
          <p class="subtitle">Connector Product</p>
          <b-form-select
            v-if="type == 'Source'"
            class="shape-dropdown"
            v-model="tool"
            :disabled="true"
            :options="sourceTools"
          ></b-form-select>
          <b-form-select
            v-else
            class="shape-dropdown"
            :disabled="true"
            v-model="tool"
            :options="targetTools"
          ></b-form-select>
          <p v-if="tokenOnly.includes(tool)" class="subtitle mt-3">
            Token
            <b-icon
              icon="info-circle-fill"
              scale="1"
              style="cursor: pointer"
              variant="secondary"
              title="Please click on the info icon to see how to generate the Token."
              @click="openTokenArticle"
            ></b-icon>
          </p>

          <b-form-input
            v-if="tool == 'Trello'"
            v-model="token"
            :disabled="true"
            id="TrelloTokenId"
            type="text"
            placeholder="Filled automatically"
            class="shape col-12"
          ></b-form-input>

          <p v-if="tool == 'ClickUp'" class="subtitle mt-3">ClickUp Code</p>
          <b-form-input
            v-if="tool == 'ClickUp'"
            v-model="clickUpCode"
            :disabled="true"
            id="ClickUpCodeId"
            type="text"
            placeholder="Filled automatically"
            class="shape col-12"
          ></b-form-input>

          <p v-if="tool == 'Monday.com'" class="subtitle mt-3">
            Monday.com Code
          </p>
          <b-form-input
            v-if="tool == 'Monday.com'"
            v-model="mondayCode"
            :disabled="true"
            id="MondayCodeId"
            type="text"
            placeholder="Filled automatically"
            class="shape col-12"
          ></b-form-input>

          <b-form-input
            v-if="tool === 'Asana' || tool === 'Wrike' || tool === 'Wrike'"
            v-model="token"
            id="testHardy"
            type="text"
            placeholder="Enter Token"
            class="shape col-12"
          ></b-form-input>

          <p
            v-if="isOffice365(tool) || tool === 'Teams User Chats'"
            class="subtitle mt-3"
          >
            Grant Admin Consent
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="Click on the button to authorize the Altosio AD application. This step requires Global Admin credentials."
            ></b-icon>
          </p>
          <b-button
            v-if="isOffice365(tool) || tool === 'Teams User Chats'"
            variant="primary"
            class="shape col-12"
            v-on:click="openAppLink()"
            >Grant Consent to Altosio AD Application</b-button
          >

          <p v-if="tool === 'Slack'" class="subtitle mt-3">
            Grant Consent
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="Click on the button to authorize the Altosio Slack Application in your Workspace"
            ></b-icon>
          </p>
          <b-button
            v-if="tool === 'Slack'"
            variant="primary"
            class="shape col-12"
            v-on:click="openAppLink()"
            >Connect to Slack</b-button
          >

          <p
            v-if="isOffice365(tool) || tool === 'Teams User Chats'"
            class="subtitle mt-3"
          >
            Domain
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="The domain can be either company.com or company.onmicrosoft.com"
            ></b-icon>
          </p>
          <b-form-input
            v-if="isOffice365(tool) || tool === 'Teams User Chats'"
            v-model="domain"
            type="text"
            placeholder="Domain"
            class="shape col-12"
          ></b-form-input>

          <p v-if="isOffice365(tool)" class="subtitle mt-3">
            Password
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="Password of the migration account"
            ></b-icon>
          </p>
          <b-form-input
            v-if="isOffice365(tool)"
            v-model="password"
            type="password"
            placeholder="Password"
            class="shape col-12"
          ></b-form-input>
          <p v-if="tool === 'Monday.com'" class="subtitle mt-3">
            Second: Connect to Monday.com
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="Click on the button to link your Monday.com Workspace"
            ></b-icon>
          </p>

          <b-button
            v-if="tool === 'Monday.com'"
            variant="primary"
            class="shape col-12"
            v-on:click="openMondayAppWindow()"
          >
            <img src="../../assets/monday.png" class="icon" />
            Connect to Monday.com
          </b-button>
          <!-- Start IsForBackup -->
          <p v-if="tool === 'Trello'" class="subtitle mt-3">
            Is this connector for Backup?
            <b-icon
              icon="info-circle-fill"
              scale="1"
              variant="secondary"
              title="Only tick this if you are doing Trello Backup"
            ></b-icon>
          </p>
          <switchToggle
            v-if="tool === 'Trello'"
            class="switch"
            :switchState="isForBackup"
            @change-switch-state="changeToggleState"
          >
          </switchToggle>
          <!-- End IsForBackup -->
        </div>
      </div>
      <div class="button mb-5">
        <b-button variant="primary" class="botton" v-on:click="saveConnector()"
          >Save Changes</b-button
        >
      </div>
    </div>
  </b-overlay>
</template>

<script>
import api from "@/api";
import switchToggle from "../inputs/switchToggle.vue";
export default {
  name: "EditConnector",
  components: {
    switchToggle,
  },
  data() {
    return {
      type: "",
      tool: "",
      types: ["Source", "Target"],
      sourceTools: [
        "Trello",
        "Asana",
        "Todoist",
        "Planner",
        "Wrike",
        "Teams",
        "Slack",
        "Teams User Chats",
        "ClickUp",
        "Monday.com",
      ],
      targetTools: [
        "Planner",
        "Trello",
        "Asana",
        "Teams",
        "Sharepoint",
        "Teams User Chats",
        "Monday.com",
      ],
      tokenOnly: ["Asana", "Todoist", "Trello", "Wrike"],
      token: "",
      appKey: "",
      domain: "",
      tenant: "",
      clientId: "",
      clientSecret: "",
      username: "",
      password: "",
      name: "",
      clickUpCode: "",
      mondayCode: "",
      isForBackup: false,
      showOverlay: false,
      project: null,
    };
  },
  props: {
    connectorId: Number,
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
    api.validateToken().then(
      () => {},
      () => {
        this.$router.push({ path: "/login" });
      }
    );
    this.getConnector(this.project, this.connectorId);
  },
  methods: {
    openTrelloAppWindow() {
      var left = screen.width / 2 - 600 / 2;
      var top = screen.height / 2 - 700 / 2;
      this.appKey = "8995f5226c825c95a461ca66c271ffca";
      if (this.type === "Source") {
        window.open(
          "https://trello.com/1/authorize?expiration=30days&name=Altosio&scope=read&response_type=token&key=" +
            this.appKey +
            "&return_url=https://app.altosio.com/TrelloAppConsent",
          "TrelloConsent",
          "width=600, height=700, top=" + top + ", left=" + left
        );
      } else {
        window.open(
          "https://trello.com/1/authorize?expiration=30days&name=Altosio&scope=read,write&response_type=token&key=" +
            this.appKey +
            "&return_url=https://app.altosio.com/TrelloAppConsent",
          "TrelloConsent",
          "width=600, height=700, top=" + top + ", left=" + left
        );
      }
    },
    openClickUpAppWindow() {
      var left = screen.width / 2 - 600 / 2;
      var top = screen.height / 2 - 700 / 2;
      window.open(
        "https://app.clickup.com/api?client_id=0OAD7TELCABS4X4ARNQO0KUYNKWRYB6S&redirect_uri=app.altosio.com/ClickUpAppConsent",
        "ClickUpConsent",
        "width=600, height=700, top=" + top + ", left=" + left
      );
    },
    openMondayAppWindow() {
      var left = screen.width / 2 - 600 / 2;
      var top = screen.height / 2 - 700 / 2;
      if (this.type === "Source") {
        window.open(
          "https://auth.monday.com/oauth2/authorize?client_id=c6a97179f331c1f9855e7225258b37b2",
          "Monday.com Consent",
          "width=600, height=700, top=" + top + ", left=" + left
        );
      } else {
        window.open(
          "https://auth.monday.com/oauth2/authorize?client_id=861a28f797d178c236b6d6f3f87f73d9",
          "Monday.com Consent",
          "width=600, height=700, top=" + top + ", left=" + left
        );
      }
    },
    openTokenArticle() {
      if (this.tool === "Asana") {
        window.open("https://altosio.com/how-to-generate-asana-token/");
      } else if (this.tool === "Trello") {
        window.open("https://altosio.com/trello-migration-guide/");
      }
    },
    installAltosioAppOnMondayEnvironment() {
      var left = screen.width / 2 - 600 / 2;
      var top = screen.height / 2 - 700 / 2;
      if (this.type === "Source") {
        window.open(
          "https://auth.monday.com/oauth2/authorize?client_id=c6a97179f331c1f9855e7225258b37b2&response_type=install",
          "Monday.com App Install",
          "width=600, height=700, top=" + top + ", left=" + left
        );
      } else {
        window.open(
          "https://auth.monday.com/oauth2/authorize?client_id=861a28f797d178c236b6d6f3f87f73d9&response_type=install",
          "Monday.com App Install",
          "width=600, height=700, top=" + top + ", left=" + left
        );
      }
    },
    openAppLink() {
      if (this.isOffice365(this.tool)) {
        window.open(
          "https://login.microsoftonline.com/common/adminconsent?client_id=94ec2a1e-f427-4de2-88e3-f049d56353dd&state=12345"
        );
      } else if (this.tool === "Teams User Chats") {
        window.open(
          "https://login.microsoftonline.com/common/adminconsent?client_id=3ad56f54-e0c4-4146-a2b7-6256dff799bf&state=12345"
        );
      } else if (this.tool === "Slack") {
        window.open(
          "https://slack.com/oauth/v2/authorize?scope=users:read.email,users:read,mpim:history,im:read,im:history,groups:history,files:read,chat:write,channels:read,channels:history&user_scope=users:read.email,users:read,mpim:history,im:read,im:history,groups:history,files:read,chat:write,channels:read,channels:history&client_id=2504972072608.5473304493846&tracked=1"
        );
      }
    },
    isOffice365(tool) {
      if (tool === "Planner" || tool === "Teams" || tool === "Sharepoint") {
        return true;
      } else {
        return false;
      }
    },
    saveConnector() {
      this.showOverlay = true;
      const endpointType = this.getEndpointType(this.tool);
      const isTarget = this.type === "Target";
      const endpointSettings = JSON.stringify(this.getSettings(this.tool));
      const form = {
        name: this.name,
        endpointType,
        isTarget,
        endpointSettings,
        isForBackup: this.isForBackup,
      };
      api.updateEndpoint(form, this.project, this.connectorId).then(
        () => {
          const message = "success";
          this.$emit("save-changes", message);
          this.$bvModal.hide("edit");
          this.showOverlay = false;
        },
        (error) => {
          const message = error.responseData;
          this.$emit("save-changes", message);
          this.$bvModal.hide("edit");
          this.showOverlay = false;
        }
      );
    },
    changeToggleState() {
      this.isForBackup = !this.isForBackup;
    },
    getConnector(projectId, endpointId) {
      api.getEndpoint(projectId, endpointId).then((response) => {
        this.name = response.responseData.name;
        this.type = response.responseData.isTarget ? "Target" : "Source";
        this.isForBackup = response.responseData.isForBackup;
        this.tool = this.getType(response.responseData.endpointType);
        let config = JSON.parse(response.responseData.endpointSettings);
        this.processConfig(config);
      });
    },
    processConfig(config) {
      if ("Code" in config) {
        if (this.tool === "ClickUp") {
          this.clickUpCode = config["Code"];
        } else if (this.tool === "Monday.com") {
          this.mondayCode = config["Code"];
        }
      }
      if ("appKey" in config) {
        this.appKey = config["appKey"];
      }
      if ("token" in config) {
        this.token = config["token"];
      }
      if ("ClientId" in config) {
        this.clientId = config["ClientId"];
      }
      if ("ClientSecret" in config) {
        this.clientSecret = config["ClientSecret"];
      }
      if ("Domain" in config) {
        this.domain = config["Domain"];
      }
      if ("Password" in config) {
        this.password = config["Password"];
      }
      if ("TenantName" in config) {
        this.tenant = config["TenantName"];
      }
      if ("UserName" in config) {
        this.username = config["UserName"];
      }
    },
    getEndpointType(tool) {
      if (tool === "Planner") return 0;
      else if (tool === "Trello") return 1;
      else if (tool === "Asana") return 2;
      else if (tool === "Todoist") return 3;
      else if (tool === "Wrike") return 4;
      else if (tool === "Teams") return 5;
      else if (tool === "Sharepoint") return 8;
      else if (tool === "Slack") return 9;
      else if (tool === "Teams User Chats") return 11;
      else if (tool === "ClickUp") return 12;
      else if (tool === "Monday.com") return 13;
    },
    getType(endpointId) {
      if (endpointId === 0) return "Planner";
      else if (endpointId === 1) return "Trello";
      else if (endpointId === 2) return "Asana";
      else if (endpointId === 3) return "Todoist";
      else if (endpointId === 4) return "Wrike";
      else if (endpointId === 5) return "Teams";
      else if (endpointId === 8) return "Sharepoint";
      else if (endpointId === 9) return "Slack";
      else if (endpointId === 11) return "Teams User Chats";
      else if (endpointId === 12) return "ClickUp";
      else if (endpointId === 13) return "Monday.com";
    },
    getSettings(tool) {
      if (this.isOffice365(tool)) {
        return {
          Domain: this.domain.trim(),
          TenantName: this.tenant,
          ClientId: this.clientId,
          ClientSecret: this.clientSecret,
          UserName: this.username.trim(),
          Password: this.password,
        };
      } else if (tool === "Teams User Chats") {
        return {
          Domain: this.domain.trim(),
          TenantName: this.tenant,
          ClientId: this.clientId,
          ClientSecret: this.clientSecret,
        };
      } else if (tool === "Trello") {
        return {
          appKey: this.appKey,
          token: this.token,
        };
      } else if (tool === "Asana") {
        return {
          token: this.token,
        };
      } else if (tool === "Todoist") {
        return {
          token: this.token,
        };
      } else if (tool === "Wrike") {
        return {
          token: this.token,
        };
      } else if (tool === "ClickUp") {
        return {
          Code: this.clickUpCode,
          Token: "",
        };
      } else if (tool === "Monday.com") {
        return {
          Code: this.mondayCode,
          Token: "",
        };
      }
    },
  },
};
</script>

<style scoped>
.all {
  margin-left: 60px;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.shape {
  width: 480px;
  height: 56px;
}
.shape-dropdown {
  width: 480px;
  text-align-last: center;
  height: 56px;
}
.botton {
  margin-top: 30px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.button {
  text-align: center;
}
.switch {
  margin-top: 12px;
}
</style>
