<template>
  <div>
    <sidebar />
    <div class="main">
      <upbar title="Connectors" class="pb-5" /> <br />
      <b-alert v-if="message != ''" variant="danger" disimissable show="5"
        >Error creating connector: {{ message }}</b-alert
      >
      <div class="plus" v-b-modal.create-connector>
        <div class="plusIn">
          <img src="../assets/plus.png" class="iconPlus" />
          <span> New Connector </span>
        </div>
      </div>

      <br />
      <div class="bar row">
        <div class="col-1"></div>
        <div class="col-4">
          <p class="tabTitle">Connector Name</p>
        </div>
        <div class="col-2">
          <p class="tabTitle">Type</p>
        </div>
        <div class="col-2">
          <p class="tabTitle">Connection Test</p>
        </div>
        <div class="col-3">
          <p class="tabTitle">Actions</p>
        </div>
      </div>

      <div v-for="conn in connectors" :key="conn.id" class="bar1 row">
        <div class="col-1">
          <img :src="getLogo(conn.endpointType)" class="icon" />
        </div>
        <div class="col-4">
          <p class="tabText">
            {{ conn.name }}
          </p>
        </div>
        <div class="col-2">
          <p class="tabText">{{ conn.isTarget ? "Target" : "Source" }}</p>
        </div>
           <div class="col-2">
          <div class="buttons">
            <b-icon title="Test connection failed. Please click to rerun it."
              class="bicon touch"
              variant="danger"
              icon="x-circle-fill"
              aria-hidden="true"
              v-if="conn.testState === 2"
              @click="modalTestConnection(conn.id)" 
            >
            </b-icon>
              <b-icon title="Test connection successful."
              class="bicon touch"
              variant="success"
              icon="check-circle-fill"
              aria-hidden="true"
              v-if="conn.testState === 1" 
              @click="modalTestConnection(conn.id)"
            >
            </b-icon>
              <b-icon title="Test connection was not run. Please click to run it."
              class="bicon touch"
              variant="warning"
              icon="exclamation-triangle-fill"
              aria-hidden="true"
              v-if="conn.testState === 0" 
              @click="modalTestConnection(conn.id)"
            >
            </b-icon>
          </div>
        </div>
        <div class="col-3">
          <div class="buttons">
            <b-icon 
              class="bicon touch"
              variant="primary"
              icon="pencil-square"
              aria-hidden="true"
              @click="modalEdit(conn.id)"
            >
            </b-icon>
            <b-icon
              class="bicon touch"
              variant="danger"
              icon="trash-fill"
              aria-hidden="true"
              @click="modalDelete(conn.id)"
            >
            </b-icon>
          </div>
        </div>
      </div>

      <b-modal size="xl" id="create-connector" hide-footer>
        <template #modal-title>
          <span class="title"> Create a new connector </span>
        </template>
        <createConnector @create-connector="finishConnectorCreation" />
      </b-modal>

      <b-modal size="xl" id="test-connection" hide-footer>
        <template #modal-title>
          <span class="title"> Connection Test </span>
        </template>
        <testConnector @test-conn="finishConnectionTest" :connectorId="connId"/>
      </b-modal>

      <b-modal size="lg" id="warning" hide-footer>
        <deleteConnector
          @delete-connector="finishConnectorDeletion"
          :connectorId="connId"
        />
      </b-modal>

      <b-modal size="xl" id="edit" hide-footer>
         <template #modal-title>
          <span class="title"> Edit Connector</span>
        </template>
        <editConnector @save-changes="saveChanges" :connectorId="connId" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import api from "../api";
import sidebar from "../components/sidebar.vue";
import upbar from "../components/upbar.vue";
import createConnector from "../components/popup/createConnector.vue";
import deleteConnector from "../components/popup/deleteConnector.vue";
import testConnector from "../components/popup/testConnector.vue";
import editConnector from "../components/popup/editConnector.vue";

export default {
  name: "Connectors",
  components: {
    sidebar,
    upbar,
    createConnector,
    deleteConnector,
    testConnector,
    editConnector,
  },
  data() {
    return {
      connectors: [],
      logged: false,
      connId: null,
      message: ""
    };
  },
  mounted() {
    this.logged = this.$store.getters.isAuthenticated;
  },
  created() {
    this.getEndpoints(this.$route.params.id);
  },
  methods: {
     makeToast(title, variant, textToShow) {
        this.$bvToast.toast(textToShow, {
          title: title,
          variant: variant,
          toaster: 'b-toaster-top-center',
          solid: false
        })
    },
    finishConnectorCreation(message) {
      //reload the projects
      this.getEndpoints(this.$route.params.id);
      if (message != "success") {
        this.message = message;
      }
    },
    finishConnectionTest() {
      this.getEndpoints(this.$route.params.id);
    },
    saveChanges(errorMessage) {
      //Reload
      this.getEndpoints(this.$route.params.id);
      if (errorMessage != "success") {
         this.makeToast('Oops. Something went wrong...', 'danger', errorMessage)
      }
      else{
        this.makeToast('Success', 'success', 'Connector updated.')
      }
    },
    finishConnectorDeletion(connectorId) {
      //Better than reloading
      const itemIndex = this.connectors.findIndex((x) => x.id === connectorId);
      this.connectors.splice(itemIndex, 1);
    },
    addConnector() {
      this.message = "";
      this.$router.push({ path: "/new-connector/" + this.$route.params.id });
    },
    getEndpoints(id) {
      api.getEndpoints(id).then(
        (response) => {
          this.connectors = response.responseData;
        },
        (error) => {
          this.makeToast('Oops! Something went wrong', 'danger', error.responseData); 
        }
      );
    },
    modalTestConnection(connId) {
      this.connId = connId;
      this.$bvModal.show("test-connection");
    },
    modalDelete(connId) {
      this.connId = connId;
      this.$bvModal.show("warning");
    },
    modalEdit(connId) {
      this.connId = connId;
      this.editErrorMessage = "";
      this.$bvModal.show("edit");
    },
    getLogo(id) {
      const EndpointImages = {
        0: "./planner.png",
        1: "./trello.png",
        2: "./asana.png",
        3: "./todoist.png",
        4: "./wrike.png",
        5: "./teams.png",
        8: "./sharepoint.png",
        9: "./slack.png",
        11: "./teams-chat.png",
        12: "./clickup.png",
        13: "./monday.png",
      };
      var images = require.context("../assets/", false, /\.png$/);
      return images(EndpointImages[id]);
    },
  },
};
</script>
<style scoped>
.main {
  margin-top: 53px;
  margin-left: 370px;
}
.bar {
  margin-top: 53px;
  margin-right: 40px;
  height: 53px;
  border-radius: 2px;
  background: #f2f6ff;
}
.bar1 {
  margin-right: 40px;
  height: 82px;
  border-radius: 2px;
  border-bottom: 2px solid #dfe0eb;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #23438e;
  margin-left: 400px;
  margin-right: auto;
}
.tabTitle {
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  color: #2a4192;
}
.tabText {
  margin-top: 28px;
  font-size: 20px;
  line-height: 21px;
  color: #252733;
}
.buttons {
  margin-top: 18px;
}
.botton {
  width: 155px;
  height: 44px;
  margin-right: 25px;
}
.icon {
  margin-right: 25px;
  margin-top: 24px;
}
.touch {
  cursor: pointer;
}
.plus {
  margin-right: 40px;
  float: right;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}
.plusIn {
  margin-top: 10px;
  margin-left: 40px;
}
.bicon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.iconPlus {
  margin-right: 20px;
}
</style>
