<template>
  <div class="all">
    <div>
        <b-spinner v-if="message===''" label="Loading..."></b-spinner>
        <p v-else class="subtitle">
        {{message}}
      </p>
    </div>
   
    <b-button variant="primary" class="blue" v-on:click="close()">Close</b-button>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "TestConnection",
  data() {
    return {
       message: ""
    };
  },
  props: {
    connectorId: Number,
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
  },
  created() {
    this.runTest();
  },
  methods: {
    runTest() {
      this.project = this.$route.params.id;
      api.testConnector(this.project, this.connectorId).then(
        (response) => {
          this.message =  response.message; 
          this.$emit('test-conn', this.connectorId);
        },
        (error) => {
           this.message = "Test connection failed. \r\n "+  error.responseData; 
           this.$emit('test-conn', this.connectorId);
        }
      );
      
    },
    close() {
      this.$bvModal.hide("test-connection");
    },
  },
};
</script>

<style scoped>
.all {
  text-align: center;
  margin-bottom: 40px;
}
.title {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #232c68;
}
.subtitle {
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #232c68;
}
.red {
  width: 170px;
  height: 40px;
  border: 2px solid #f03738;
  border-radius: 52px;
  margin-right: 40px;
  margin-top: 30px;
}
.blue {
  margin-top: 30px;
  width: 170px;
  height: 40px;
  background: #232c68;
  border: 0.5px solid #c1c1c1;
  border-radius: 52px;
}
</style>
